<template>
 <div style='width: 100%; height: 100%; position: relative;'>
 
    <div class='transition' :style='getControlStyle()'>
			<div style='float: top; width: 100%; vertical-align: middle;'>
		        <div style='vertical-align: middle; text-align: center; font-size: 12pt; font-weight: bold;width: 100%;'>Booking MediaManager/Monitor (TVLOAD) 1.0</div>
		        <div style='vertical-align: middle; text-align: center; font-size: 9pt; font-weight: bold;width: 100%;'>CONTROLS</div>

				<div v-if="data && data.media">
			         <center>
			    		<GImage :data="getImgData( data.media.logo)"/>
			         </center>
			    </div>
		       
		        <span class='SVcontrolLabel'>Channel/Station:</span><br/>
			
			    <GSelectSimple :options="stations" style='width:100%' v-model="stationId" />
				
				<span class='SVcontrolLabel'>Date:</span><br/><InputDatePick :auto="false" v-model="date"/>
				<br/>
				
				<span class='SVcontrolLabel'>Connection:</span><br/>
							
				<GSelectSimple :options="interfaces" style='width:100%' v-model="interfaceId" />
			  
				<br/><br/>
				<div v-if="allAdvertiser && allAdvertiser.length">
					<span class='SVcontrolLabel'>Advertiser (Filter):</span><br/>
					<GSelectSimple  :options="allAdvertiser" style='width:100%' v-model="advertiser" />
				</div>
				<div v-if="allCopies && allCopies.length">
					<span class='SVcontrolLabel'>Copy (Filter):</span><br/>
					<GSelectSimple :options="allCopies" style='width:100%' v-model="copy" />
				</div>

				<br/><br/>

				<div v-if="!fileImported && data && data.data && data.data.length">
					<button type="button" class="upbutton" @click="importBookings">Import this file</button>
					As <span style='display: inline-block;width: 60pt;' v-if="set2Fixed">FIXED</span>
					<span style='display: inline-block;width: 60pt;' v-else>UNFIXED</span>
					
					<app-switch v-model="set2Fixed" :checked="set2Fixed"/>
				</div>		
				<div v-else-if="stationId || (data && (data.media && data.media.id))">
					<button type="button" class="upbutton" @click="load()">LOAD BOOKINGS</button>
				</div>			

				<br/>
				<div v-if="data && data.media">
									
					
					<div v-if="copy">
						<button :title="'unfix all spots of '+allCopies.find(p=>p.id==copy).label" :disabled="!data.existing || !data.existing.length" type="button" class="xlbutton" @click="fixAll(0,advertiser,copy)"><mdicon name="pin-off"/> {{allCopies.find(p=>p.id==copy).label}}</button>
						<button :title="'fix all spots of '+allCopies.find(p=>p.id==copy).label" :disabled="!data.existing || !data.existing.length" type="button" class="xlbutton" @click="fixAll(1,advertiser,copy)"><mdicon name="pin"/> {{allCopies.find(p=>p.id==copy).label}}</button>
					</div>
					<div v-else-if="advertiser">
						<button :title="'unfix all spots of '+allAdvertiser.find(p=>p.id==advertiser).label" :disabled="!data.existing || !data.existing.length" type="button" class="xlbutton" @click="fixAll(0,advertiser)"><mdicon name="pin-off"/> {{allAdvertiser.find(p=>p.id==advertiser).label}}</button>
						<button :title="'fix all spots of '+allAdvertiser.find(p=>p.id==advertiser).label" :disabled="!data.existing || !data.existing.length" type="button" class="xlbutton" @click="fixAll(1,advertiser)"><mdicon name="pin"/> {{allAdvertiser.find(p=>p.id==advertiser).label}}</button>
					</div>
					<div v-else>
						<button title="unfix all spots" :disabled="!data.existing || !data.existing.length" type="button" class="upbutton" @click="fixAll(0)"><mdicon name="pin-off"/> all</button>
						<button title="fix all spots" :disabled="!data.existing || !data.existing.length" type="button" class="upbutton" @click="fixAll(1)"><mdicon name="pin"/> all</button>					
				    </div>
					<br/>

					<button :disabled="!data.existing || !data.existing.length || !interfaceId" 
					        :style="activeInterface && activeInterface.production?'background-color: #c88':''"
					        type="button" class="vmbutton" @click="syncMaterial(data.spots)"><mdicon name="sync-circle"/> AdSpots</button>
					
					<button :disabled="!data.existing || !data.existing.length || !interfaceId || !hasSyncTime" 
							:style="activeInterface && activeInterface.production?'background-color: #c88':''"
					        type="button" class="vmbutton" @click="syncBreaks(data.spots)"><mdicon name="sync-circle"/> AdBlocks</button>
					<button :disabled="!data.existing || !data.existing.length || !interfaceId || !hasSyncTime" 
							:style="activeInterface && activeInterface.production?'background-color: #c88':''"
						    type="button" class="vmbutton" @click="syncAudience()"><mdicon name="sync-circle"/> Audience</button>
					<br/>
						<button  
							:style="activeInterface && activeInterface.production?'background-color: #c88':''"
						    type="button" class="vmbutton" @click="loader()"><mdicon name="sync-circle"/> LOADER</button>
					<br/>
					<!--Force AdBlock-Update <app-switch v-model="forceUpdate" :checked="forceUpdate"/>-->
					
					<br/>
					<div v-if="data.syncResult && data.syncResult.counter" style='border: 2pt inset grey;'>
						Log:
						<div style="height: calc(8vH) !important; overflow-y: auto">
						<table style="font-size: 8pt;">
							<tr><td>total</td><td>{{data.syncResult.counter.total}}</td></tr>
							<tr><td>updates</td><td>{{data.syncResult.counter.updates}}</td></tr>
							<tr><td>inserts</td><td>{{data.syncResult.counter.inserts}}</td></tr>
							<tr v-for="(l,li) in data.syncResult.log" :key="'log'+li">
								<td colspan="2">{{l}}</td></tr>
						</table>	
						</div>
					</div>	
				</div>	
			</div>   
			
			

	</div>
	
    <div class='transition uploadArea' style='float: top; position: relative;' id='uploader' 
		 		@drop="dropFile($event, 0, 0, 'uploader');"
			    @dragenter.prevent="hoverLine( 'uploader')"
			    @dragleave.prevent="leaveLine( 'uploader')"		        
				@dragover.prevent="hoverLineMain( 'uploader')" >
				<div v-if="data && data.media && data.date">
					<div style='float: top; display: flex; width: 99.8% !important;' class='RDHeader' >
					        <div style='display: inline-flex; width: 85% !important;' >
					        	<div style='width:8%'>
						        {{dateFMT(data.date)}}
						        </div>
						        <div style='width:12%'>
						        {{data.media.shortname}}
						    	</div>
								
								<div style='width:65%'>
						        Spots: {{data.existing.length}} (Unfixed: {{data.existing.filter(p=>p.assignmentValue=="UNFIXED").length}} spots)
								<span style="padding-left: 5ex;">
								Blocks: {{times.length}} 
								</span>
								<span v-if="data.data.length" style="padding-left: 5ex;">
								Size: {{Math.round(data.data.length/1024)}}kB, {{data.nSpots}} Spots
								</span>
						    	</div>
							</div>
							

					    </div>

					<div v-if="times" style="height: calc(82vH); overflow-y: auto;">
						<div :style="'position: sticky; top: 0; font-size: 11pt; font-weight: bold;'+getAppStyle()">
							<div class="breakVal">Time</div> 
							<div class="breakVal">Sync</div> 
							 
							<div class="breakValL">Program</div> 
							<div class="breakVal">Code</div>
							<div class="breakVal right">Spots</div> 
							<div class="breakVal right">Unfixed</div> 
							<div class="breakVal right">Fixed</div> 
							<div class="breakVal right">Duration</div> 
							<div class="breakVal2 right">Total reach</div> 
							<div v-if="copy" class="breakValL right">{{allCopies.find(p=>p.id==copy).label}}</div> 
							<div v-else-if="advertiser" class="breakValL right">{{allAdvertiser.find(p=>p.id==advertiser).label}}</div> 
						</div>
						<div v-for="t in times" :key="'t'+t">
							<div class="breakLine" @click="openBreak( t)" style='font-size: 11pt; font-weight: bold; cursor: pointer;'>
								<div class="breakVal nobold">{{ printTimeHHMM( t)}}</div> 
								<div class="breakVal">{{ printTimeHHMMSS( spots.find(p=>p.starttime===t).syncTime)}} </div> 
								<div class="breakValL nobold" :style="spots.filter(p=>p.starttime===t && p.assignmentValue=='UNFIXED').length?'color: #999;':''">{{ spots.find(p=>p.starttime===t).programAfter}}</div> 
								<div class="breakVal" v-if="spots.filter(p=>(p.starttime===t && (advertiser && p.clientId==advertiser) && (!copy || p.housenumber==copy))).length" style="color: #88f;">{{ spots.find(p=>p.starttime===t).breakCode}}</div> 
								<div class="breakVal" v-else>{{ spots.find(p=>p.starttime===t).breakCode}}</div> 
								<div class="breakVal right nobold">{{ spots.filter(p=>p.starttime===t).length}}</div> 
								<div class="breakVal right" v-if="spots.filter(p=>(p.starttime===t && (advertiser && p.clientId==advertiser) && (!copy || p.housenumber==copy))).length" style="color: #88f;">{{ spots.filter(p=>p.starttime===t && p.assignmentValue=="UNFIXED").length}}</div> 
								<div class="breakVal right nobold" v-else>{{ spots.filter(p=>p.starttime===t && p.assignmentValue=="UNFIXED").length}}</div> 
								<div class="breakVal right nobold">{{ spots.filter(p=>p.starttime===t && p.assignmentValue=="FIXED").length}}</div> 
								<div class="breakVal right nobold">{{ printTime(spots.filter(p=>p.starttime===t).map(p=>p.length).reduce((a,b)=>a+b))}}</div> 
								<div class="breakVal2 right nobold">{{ spots.find(p=>p.starttime===t).totalReach}}</div> 
								<div class="breakValL right" v-if="advertiser && spots.filter(p=>p.starttime===t && ((!advertiser || p.clientId==advertiser) && (!copy || p.housenumber==copy))).length">{{ spots.filter(p=>p.starttime===t && (p.clientId===advertiser) && (!copy || p.housenumber==copy)).length}}</div> 
							</div>
							
							<div v-if="openedBreak === t">
								<table>
								<tr v-for="(s,si) in spotsInBreak" :key="'sp'+si" class="spotLine" @click="change(s)" :style="(!s.assignment?'color: #999;':'')">
									<td class="spotTdS">{{s.position}}</td>
									<td v-if="(advertiser && s.clientId==advertiser) && (!copy || s.housenumber==copy)" class="spotTdL" style="color: #88f;">{{s.advertiser}}</td>
									<td v-else class="spotTdL">{{s.advertiser}}</td>
									<td class="spotTdM2">{{s.spotGroup}}</td>
									<td class="spotTdM right">{{formatNumber( s.gross)}}</td>
									<td class="spotTdM right">{{s.length}}</td>
									<td class="spotTdM right">{{s.housenumber}}</td>
									<td v-if="(!advertiser && s.clientId==advertiser) && (!copy || s.housenumber==copy)" class="spotTdL" style="color: #88f;">{{s.spot}}</td>
									<td v-else class="spotTdL">{{s.spot}}</td>
									<td class="spotTdM2">{{s.assignmentValue}}</td>
								</tr>	
								</table>
							</div>	
						</div>	
					</div>
				</div>
				<div v-else style='padding-top: 50pt; display: block; text-align: center; vertical-align: middle; align-items: center; justify-content: center; height: 100%; width: 100; border: 2pt solid #fcd;'>
			      <span style='color: #aaa;'>drop booking file</span>
			    </div>
		 
 	</div>
 	
	<ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem v-if="contextData.header" @clicked="$refs.menu.close(); $refs.editor.open('GridHeader', contextData.header.id)" >
          Open {{contextData.header.name}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.detail" @clicked="$refs.menu.close(); $refs.editor.open('GridDetail', contextData.detail.id)" >
          Open {{contextData.detail.name}}
        </ContextMenuItem>
        <ContextMenuSep v-if="!contextData.detail"/>
        <ContextMenuItem v-if="!contextData.header.active && !contextData.detail" @clicked="$refs.menu.close(); activate( contextData.header)">
          Activate {{contextData.header.name}}
        </ContextMenuItem>
        <ContextMenuItem v-if="!contextData.header.active && !contextData.detail" @clicked="$refs.menu.close(); compare( contextData.header)">
          Compare
        </ContextMenuItem>
       </template>
    </ContextMenu>
	<PDFViewer name='pdfView' ref='pdfView' />
    <GFWEOpenEditor ref='editor' @update='reload'></GFWEOpenEditor>
	<GConfirm ref='confirm'/>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
 </div>
</template>	
<script>
import {HTTP, HTTPMP, gridAPI, bookingImportAPI, reportAPI, opsAPI, bngAPI, fwAPI, userAPI, invAPI, setReload, showError, myLocale, formatNumber} from '@/variables.js';
import {store, restore} from '@/gridImport.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import { setGoBack } from '@/breadCrumb.js';
import {printTimeOpt, printTimeHHMM, printTimeHHMMSS, printTime } from '@/basicTimeFN.js';
import ProgressBar from '@/components/ProgressBar';
import Switch from '@/components/Switch';
import GImage from '@/components/GImage';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuSep from '@/components/ContextMenuSep';
import ContextMenuItem from '@/components/ContextMenuItem';
import RunDownAsRun from '@/components/asrun/RunDownAsRun';
import InputWeekdays from '@/components/inputElements/DisplayWeekdays';
import RunDown from '@/components/asrun/RunDown';
import GSelectMSimple from '@/components/GSelectMSimple';
import GSelectSimple from '@/components/GSelectSimple';
import GSelect from '@/components/misc/GSelect';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import InputDatePickM from '@/components/inputElements/InputDatePickM'; 
import GridBreakView from '@/components/GridBreakView';
import GConfirm from '@/components/GConfirm';
import PDFViewer from '@/components/PDFViewer';
import JQuery from "jquery";
import 'w3-css/w3.css';
var momentTZ = require('moment-timezone')
let $ = JQuery;
var timers = [];
export default {
  name: 'GFW_IMPORTBOOKINGS',
   
  components : {
   //vSelect, 'app-switch': Switch, InputDatePick, GConfirm, GSelectSimple, RunDownAsRun, RunDown
   GConfirm, GImage, ProgressBar, InputDatePick,  PDFViewer, ContextMenu, ContextMenuItem, ContextMenuSep,GSelectSimple, 'app-switch': Switch
  },
  data () {
    return {
      stationId: 0,
	  interfaceId: 0,
	  fileImported: false,
	  activeInterface: {},
      controlsRight: false,
      station: {},
      stations: [],
	  interfaces: [],
	  interfacesFull: [],
	  hasSyncTime: false,
	  
	  reports: [],
	  user: {},
      data: {},
	  reportData: {},
      dataStored: {},
      fileId: 0,
      headers: null,
      compareData: null,
      date: null,
	  set2Fixed: true,
      dates: [],
      emptyBreaks: 1,
      emptyBreaksActive: 1,
      selectedHeader: null,
	  selectedStation: null,
	  gridImported: null,
	  allAdvertiser: [],
	  allCopies: [],
	  copy: 0,
	  
	  advertiser: 0,
      //
      times: [],
	  spots: [],
	  spotsInBreak: [],
	  openedBreak: 0,
      //
      categories: [],
      categoryIds: [],
      //
      overbook: true,
      fillLastBreak: true,
      unplaced: false,
      // ProgBar
      pbFromGridId: 0,
      pbToGridId: 0,
      pbHeaderId: 0,
      pbAction: "",
      pbData: {},
      pbTitle: "",
      showProgressBar: false,
      getBGStyle2, formatNumber,getAppStyle, printTimeHHMM, printTimeHHMMSS
      }
  },
  methods: {
	  getControlStyle() {
	    	if ( this.controlsRight )
	    	{
	    		return "float: right; width: 25%; height: calc(100vh - 120px); padding: 2ex;"
	    	}
	    	return "float: left; width: 25%; height: calc(100vh - 120px); padding: 2ex;"
	    },
	    getImgData(val) { return 'data:image/png;base64,' + val; },
	    openScheduleView(stationId, isoDay)
	    {
	    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
	    	this.$router.replace('scheduleView?stationId='+stationId+'&day='+ isoDay);
	    },
		dateFMT( date)
		{
			return new Date(date).toLocaleDateString("DE");
		},
	    getRatecard(compareData, id)
	    {
	    	if (compareData.ratecards[id])
	    	{
	    		return compareData.ratecards[id];
	    	}
	    	return {};
	    },
		change( spot)
		{
			let that = this;
			if ( spot.assignmentValue == "UNFIXED")
			{
				spot.assignment = 1;
			}
			else
			{
				spot.assignment = 0;
			}
			HTTP.post( bookingImportAPI+"/updateSpot/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+spot.id+"/"+spot.assignment )
	          .then( response => 
	          { 
	  			let data = response.data;
				spot.assignmentValue = data.assignmentValue;
				spot.assignment = data.assignment;
	        }).catch((err) => {showError( that.$toast, "File '"+name+"' not uploaded", err); that.showProgressBar = false;});
			
		},
		loader( data)
		    {
				this.pbAction = "loaderInternal";
		        this.pbTitle = "Import all bookings";
		 	    this.showProgressBar = true;
		    },
		loaderInternal( myId, api)
				{
					let that = this;
					api.post( bookingImportAPI+"/processDataLoad/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+myId )
			          .then( response => 
			          { 
			  			that.showProgressBar = false;
			        }).catch((err) => {showError( that.$toast, "File '"+name+"' not uploaded", err); that.showProgressBar = false;});
					
				},
		openBreak( time)
		{
			if ( this.openedBreak == time)
			{
				this.openedBreak = 0;
				return;
			}
			this.openedBreak = time;
			let spotsInBreak = this.spots.filter(p=>p.starttime===this.openedBreak);
			spotsInBreak.sort((a,b)=>a.position-b.position);
			this.spotsInBreak = spotsInBreak;
		},
	    getGridZone(compareData, d)
	    {
	    	let zone = compareData.gridZones.find(p=>p.id==d.zoneId);
	    	if ( !zone)
	    	{
	    		return {}
	    	}
	    	return zone;
	    },
	    setDates(d)
	    {
	    
	    	this.dates =d;
	    },
		showBreaks( spots)
		{
			this.spots = spots;
			let times = spots.map(p=>p.starttime);
			times.sort( (a,b) => a-b);
			this.times =  [...new Set(times)];
		
			let clientIds = spots.map(p=>p.clientId);
			clientIds =  [...new Set(clientIds)];
			let advertisers = [];
			advertisers.push({ id: 0, label:" - no filter -", visible: true});
			for ( let id in clientIds)
			{
				let cId = clientIds[id];
				advertisers.push({ id: cId, label: spots.find(p=>p.clientId===cId).advertiser, visible: true});				
			}
			advertisers.sort( (a,b) => a.label.localeCompare(b.label));

			this.allAdvertiser = advertisers;
		},
	    
    dropFile($event, line, rowIdx, lineREF)
    {
      let that = this;
      event.preventDefault();
      event.stopPropagation();
     
      this.showDropzone = false;
      const files = event.dataTransfer.files;
      const itemArray = [...files];
      
      const item = itemArray.find((i) => true);
	  if (that.data && that.data.media && that.data.existing && that.data.existing.length && (item.name.endsWith(".xlsx") ))
	  {
	     this.$refs.confirm.confirm( {text: 'Add Forecast-file: ' + item.name, title: "Adjust Timing", button1: "Cancel", button2: "Ok"}).then(x => {
	      	  this.startLoader();
	  	   	  let check =  new Promise(function (resolve, reject) {
	  		  const formData = new FormData();
	  		  that.leaveLine(lineREF);
	  		  formData.append('file', item);      
	  		  if (item) that.uploadForecast(formData, line, rowIdx, item.name);
	  		      that.stopLoader();

	  		   });
	  	   });
	  } 
	  else if (that.data && that.data.media && (item.name.endsWith(".csv") || item.name.endsWith(".log")))
	  {
		   this.$refs.confirm.confirm( {text: 'Add Log-file: ' + item.name, title: "Adjust Timing", button1: "Cancel", button2: "Ok"}).then(x => {
		    	  this.startLoader();
		    	  let check =  new Promise(function (resolve, reject) {
		    		  const formData = new FormData();
					  that.leaveLine(lineREF);
				      formData.append('file', item);      
				      if (item) that.uploadLog(formData, line, rowIdx, item.name);
				      that.stopLoader();

				   });
		      });
	  } 
	  else
	  {
	      this.$refs.confirm.confirm( {text: 'Load Booking-file: ' + item.name, title: "Upload Bookings", button1: "Cancel", button2: "Ok"}).then(x => {
	    	  this.startLoader();
	    	  let check =  new Promise(function (resolve, reject) {
	    		  const formData = new FormData();
				  that.leaveLine(lineREF);
			      formData.append('file', item);      
			      if (item) that.upload(formData, line, rowIdx, item.name);
			      that.stopLoader();
	
			   });
	      });
	   }
    },
    fmtTimePart( aTime) {
	   	if ( aTime < 10 ) 
	   	{
	   		return "0"+aTime;
	   	}
	   	return ""+aTime;
	},
    printTime(time)
    {
      //console.log( "scanTime("+val+") " + this.time )
      //alert("scanTime("+JSON.stringify(val)+") " + this.time)
      if ( isNaN( time ) || ! time  )
      {
      	time = 0;
      }
    	let ss = time % 60;
      let mm = ((time - ss) / 60 ) % 60;
      let hh = ((time - ss - mm * 60) / 3600 ) % 60;
      let hhVal = this.fmtTimePart(hh);
      let mmVal = this.fmtTimePart(mm);
      let ssVal = this.fmtTimePart(ss);
      return mmVal+":"+ssVal;
     },
    startDragGrid( evt, source, what ) {
   		evt.dataTransfer.dropEffect = what
        evt.dataTransfer.effectAllowed = what
        evt.dataTransfer.setData('dndType', what)
        evt.dataTransfer.setData('sourceId', source.id)
   	},
   	onDropGrid (evt, target, moveType) 
    {
       const sourceId = evt.dataTransfer.getData('sourceId');
       let source = this.compareData.details.find(p=>p.id==sourceId);
       if ( !source )
       {
    	   source = this.compareData.activeDetails.find(p=>p.id==sourceId);
       }
       const dndType = evt.dataTransfer.getData('dndType');
       if ( ! moveType )
       {
       	  moveType = dndType;
       }
       this.move( source, target)
	   return;
	},
    async upload(data, line, rowIdx, name) {
      let uploadFile = await this.uploadFile(data, line, rowIdx, name);
    },
	async uploadLog(data, line, rowIdx, name) {
      let uploadFile = await this.uploadLogFile(data, line, rowIdx, name);
    },
	async uploadForecast(data, line, rowIdx, name) {
	  let uploadFile = await this.uploadForecastFile(data, line, rowIdx, name);
	},
    
    adjustBreaks()
    {
		if ( this.openedBreak)
		{
			let spotsInBreak = this.spots.filter(p=>p.starttime===this.openedBreak);
			spotsInBreak.sort((a,b)=>a.position-b.position);
			this.spotsInBreak = spotsInBreak;
		}
	},
    
    
	importBookings( data)
    {
		this.$refs.confirm.confirm( {text: "Import "+this.data.nSpots+" bookings (as "+(this.set2Fixed?"fixed":"unfixed")+")", title: "Import "+this.data.fileName, button1: "Cancel", button2: "Ok"}).then(x => {
		this.pbData = data;
        this.pbAction = "importBookingsInternal";
        this.pbTitle = "Import bookings";
 	    this.showProgressBar = true;
 	    });
    },
    importBookingsInternal( myId, api)
    {
		let that = this;
		delete this.data.syncResult;
	  	api.put( bookingImportAPI+"/loadImportedFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.set2Fixed+"/"+myId, this.data   )
	          .then( response => 
	          { 
	  			that.data = response.data;
	  			that.dataStored = that.data;
	  			that.date = new Date(that.data.date);
				that.hasSyncTime = false;
	  			if ( that.data.media )
	  			{
	  				that.stationId = that.data.media.id;
	  			}
				that.fileImported = true;
	            that.showProgressBar = false;
				that.showBreaks(that.data.existing)
	            that.$toast.success("File '"+name+"' imported'", 'Ok', { timeout: 1500, position: "topRight" });
	            that.$forceUpdate();
	        }).catch((err) => {showError( that.$toast, "File '"+name+"' not uploaded", err); that.showProgressBar = false;})
	},
	load()
	{
		let that = this;
		
		let activeDate= new Date(this.date).toISOString().split('T')[0];
		//alert(bookingImportAPI+"/load/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+activeDate)
	  	HTTP.post( bookingImportAPI+"/load/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+activeDate )
	          .then( response => 
	          { 
				
	  			that.data = response.data;
	  			that.dataStored = that.data;
				that.hasSyncTime = that.data.existing.length && !that.data.existing.find(p=>p.syncTime===null); 
	  			that.date = new Date(that.data.date);
	  			if ( that.data.media )
	  			{
	  				that.stationId = that.data.media.id;
	  			}

	            that.showProgressBar = false;
	            that.$toast.success("Data loaded", 'Ok', { timeout: 1500, position: "topRight" });
				that.times = [];
				if ( that.data.existing && that.data.existing.length)
				{
					that.showBreaks(that.data.existing);
				}
	            that.$forceUpdate();
	        }).catch((err) => {showError( that.$toast, "day lot loaded", err); that.showProgressBar = false;})
	},
	fixAll( assignment, advertiserId, copyId )
	{
		let that = this;
		let advertiser = advertiserId ? advertiserId : 0;
		let copy = copyId ? copyId : 0;
		let activeDate= new Date(this.date).toISOString().split('T')[0];
		//alert(bookingImportAPI+"/load/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+activeDate)
	  	HTTP.post( bookingImportAPI+"/fixAll/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+activeDate+"/"+assignment+"/"+advertiser+"/"+copy )
	          .then( response => 
	          { 
	  			that.data = response.data;
				that.spots = that.data.existing;
				that.adjustBreaks();
	  			that.dataStored = that.data;
				that.hasSyncTime = !that.data.existing.find(p=>p.syncTime===null); 
	  			that.date = new Date(that.data.date);
	  			if ( that.data.media )
	  			{
	  				that.stationId = that.data.media.id;
	  			}
	            that.showProgressBar = false;
	            that.$toast.success("Spots modified", 'Ok', { timeout: 1500, position: "topRight" });
	            that.$forceUpdate();
	        }).catch((err) => {showError( that.$toast, "fixAll", err); that.showProgressBar = false;})
	},
	syncMaterial( data)
    {
		this.$refs.confirm.confirm( {text: "Synchronize", title: "Sync materials", button1: "Cancel", button2: "Start"}).then(x => {
		this.pbData = data;
        this.pbAction = "syncMaterialInternal";
        this.pbTitle = "Sync materials";
 	    this.showProgressBar = true;
 	    });
    },
    syncMaterialInternal( myId, api)
    {
		let that = this;
		delete this.data.syncResult;
	  	api.post( bookingImportAPI+"/syncMaterials/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.interfaceId+"/"+myId, this.data   )
	          .then( response => 
	          { 
	  			that.data = response.data;
	  			that.dataStored = that.data;
	  			that.date = new Date(that.data.date);
	  			if ( that.data.media )
	  			{
	  				that.stationId = that.data.media.id;
	  			}
	            that.showProgressBar = false;
	            that.$toast.success(that.data.message, 'Ok', { timeout: 3000, position: "topRight" });
	            that.$forceUpdate();
	        }).catch((err) => {showError( that.$toast, "Error syncMaterials", err); that.showProgressBar = false;})
	},
	
	syncBreaks( data)
    {
		this.$refs.confirm.confirm( {text: "Synchronize", title: "Sync breaks", button1: "Cancel", button2: "Start"}).then(x => {
		this.pbData = data;
        this.pbAction = "syncBreaksInternal";
        this.pbTitle = "Sync breaks";
 	    this.showProgressBar = true;
 	    });
    },
	syncBreaksInternal( myId, api)
    {
		let that = this;
		delete this.data.syncResult;
	  	api.post( bookingImportAPI+"/syncBreaks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.interfaceId+"/"+myId, this.data   )
	          .then( response => 
	          { 
	  			that.data = response.data;
	  			that.dataStored = that.data;
	  			that.date = new Date(that.data.date);
	  			if ( that.data.media )
	  			{
	  				that.stationId = that.data.media.id;
	  			}
	            that.showProgressBar = false;
	            that.$toast.success(that.data.message, 'Ok', { timeout: 3000, position: "topRight" });
	            that.$forceUpdate();
	        }).catch((err) => {showError( that.$toast, "Error syncBreaks", err); that.showProgressBar = false;})
	},
	syncAudience( data )
    {
		this.$refs.confirm.confirm( {text: "Synchronize", title: "Sync audience", button1: "Cancel", button2: "Start"}).then(x => {
		this.pbData = data;
        this.pbAction = "syncAudienceInternal";
        this.pbTitle = "Sync audience";
 	    this.showProgressBar = true;
 	    });
    },
    syncAudienceInternal( myId, api)
    {
		let that = this;
		delete this.data.syncResult;
	  	api.post( bookingImportAPI+"/syncAudience/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.interfaceId+"/"+myId, this.data )
	          .then( response => 
	          { 
	  			that.data = response.data;
	  			that.dataStored = that.data;
	  			that.date = new Date(that.data.date);
	  			if ( that.data.media )
	  			{
	  				that.stationId = that.data.media.id;
	  			}
	            that.showProgressBar = false;
	            that.$toast.success(that.data.message, 'Ok', { timeout: 3000, position: "topRight" });
	            that.$forceUpdate();
	        }).catch((err) => {showError( that.$toast, "Error syncBreaks", err); that.showProgressBar = false;})
	},
    
    updateCompare()
    {
    	this.compareData = null;
    },
    reload()
    {
    	this.compare(this.selectedHeader);
    },
    
    uploadFile(formData, line, rowIdx, name) 
    {
      let that=this;
      

      HTTPMP.put( bookingImportAPI+"/scanFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId, formData)
        .then( response => 
        { 
			that.data = response.data;
			that.hasSyncTime = !that.data.existing.find(p=>p.syncTime===null);
			that.dataStored = that.data;
			that.fileImported = false;
			that.date = new Date(that.data.date);
			if ( that.data.media )
			{
				that.stationId = that.data.media.id;
			}
            that.stopLoader();
            that.$toast.success("File '"+name+"' uploaded'", 'Ok', { timeout: 1500, position: "topRight" });
			
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "File '"+name+"' not uploaded", err); that.stopLoader();})

    },
	uploadLogFile(formData, line, rowIdx, name) 
    {
      let that=this;
	  let activeDate= new Date(this.date).toISOString().split('T')[0];
      HTTPMP.put( bookingImportAPI+"/addLogFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+activeDate, formData)
        .then( response => 
        { 
			that.data = response.data;
			that.spots = that.data.existing;
			that.dataStored = that.data;
			that.date = new Date(that.data.date);
			that.adjustBreaks();
			that.hasSyncTime = !that.data.existing.find(p=>p.syncTime===null); 
			if ( that.data.media )
			{
				that.stationId = that.data.media.id;
			}
            that.stopLoader();
            that.$toast.success("File '"+name+"' uploaded'", 'Ok', { timeout: 1500, position: "topRight" });
			
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "File '"+name+"' not uploaded", err); that.stopLoader();})

    },
	uploadForecastFile(formData, line, rowIdx, name) 
    {
      let that=this;
	  let activeDate= new Date(this.date).toISOString().split('T')[0];
      HTTPMP.put( bookingImportAPI+"/addForecastFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+activeDate, formData)
        .then( response => 
        { 
			that.data = response.data;
			that.spots = that.data.existing;
			that.dataStored = that.data;
			that.date = new Date(that.data.date);
			that.adjustBreaks();
			that.hasSyncTime = !that.data.existing.find(p=>p.syncTime===null); 
			if ( that.data.media )
			{
				that.stationId = that.data.media.id;
			}
            that.stopLoader();
            that.$toast.success( response.data.message, 'Ok', { timeout: 1500, position: "topRight" });
			
            that.$forceUpdate();
      }).catch((err) => {showError( that.$toast, "File '"+name+"' not uploaded", err); that.stopLoader();})

    },
    action( what, myId, api)
    {
    	console.log(what+"/"+ myId+"/"+api)
   		this[what](myId, api)
    },
    importGrid( headerId)
    {
    	this.pbHeaderId = headerId;
        this.pbAction = "importGridInternal";
        this.pbTitle = "Import grid";
 	    this.showProgressBar = true;
    },
	importJustGrid( headerId)
    {
    	this.pbHeaderId = headerId;
        this.pbAction = "importJustGridInternal";
        this.pbTitle = "Import grid";
 	    this.showProgressBar = true;
    },
    importBreakGrid( headerId, data)
    {
		this.pbHeaderId = headerId;
    	this.pbData = data;
        this.pbAction = "importBreakGridInternal";
        this.pbTitle = "Import break grid";
 	    this.showProgressBar = true;

    },
    importDayGridXML( headerId, data)
    {
    	this.pbHeaderId = headerId;
    	this.pbData = data;
        this.pbAction = "importDayGridXMLInternal";
        this.pbTitle = "Import daily break grid";
 	    this.showProgressBar = true;

    },
	linkBreaks( headerId, data)
	  {
	    	this.pbHeaderId = headerId;
	    	this.pbData = data;
	        this.pbAction = "linkBreaksInternal";
	        this.pbTitle = "Link grid breaks to breaks";
	 	    this.showProgressBar = true;

	 },
    

    hoverLine(line)
    {
    	$('#'+line).addClass('reqLineBG2');
    },
    hoverLineMain(line)
    {
    	//$('#'+line).addClass('reqLineBG');
    },
    leaveLine(line)
    {
    	$('#'+line).removeClass('reqLineBG2');
    },
	runReport( report) 
	{
		this.pbAction = "runReportInternal";
    	this.pbTitle = "Run "+ report.info;
    	this.pbReport=report;
  	    this.showProgressBar = true;
	},
	
	downloadUnzip( data)
    {
		
    	this.$refs.pdfView.download(data.data, data.name);
    },
    loadStation( stationId, activeDate )
    {
    	let that = this;
    	let userLoad = new Promise(function (resolve, reject) {
            console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/asOptions/true/0");
       		HTTP.get( userAPI+"/getById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.login+"/"+sessionStorage.userId)
        		 .then( response => 
                 {
                	 that.user = response.data;
                	 that.stationIds = that.user.media;
                	 
                	 resolve (that.stationIds);
                 }).catch(e => {
                     that.$toast.error("loading user (ImportGrid): " + e.response.data, 'Error', { position: "topRight" });
                     that.stopLoader();
                     reject ();
                 });
	      });
    	userLoad.then( stationIds => {
        	let stationLoad = new Promise(function (resolve, reject) {
        	    //alert(sessionStorage.unitId);
        	    
                console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/allEntities/true/0");
				HTTP.post( bookingImportAPI+"/getSettings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
	        		 .then( response => 
	                 {
	                 	var allStations = response.data.media;
						allStations.sort((a,b)=>a.name.localeCompare(b.name));
						that.stations = allStations.map(a => {return {id: a.id, label: a.name, visible: true}});
						
						var allInterfaces = response.data.interfaces;
						allInterfaces.sort((a,b)=>a.name.localeCompare(b.name));
						that.interfaces = allInterfaces.map(a => {return {id: a.id, label: a.name, visible: true}});
						that.interfacesFull = allInterfaces;
	                   
	                    that.selectedStation =  that.stations[0];
						that.activeInterface =  that.interfaces[0];
						if ( that.activeInterface)
						{
							that.interfaceId = that.activeInterface.id;
						}

	                    resolve (that.stations);                    
	                 }).catch(e => {
	                        this.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
	                        reject ();
	                    });
    	      });
        	
        });
    },
    headerRClicked(event, header, detail)
    {
    	//alert( JSON.stringify({header: header, detail: detail}));
        event.preventDefault();
        this.$refs.menu.open(event, {header: header, detail: detail} )
    },
	getCopy( spots, id)
	  {
		let c = spots.find(p=>p.housenumber==id).spot;
		let d = spots.find(p=>p.housenumber==id).length;
		if ( c.length > 40 )
		{
			c = c.substring(0,37)+"...";
		}
		c = c + " ("+d+'")';
		return c
	  },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
	storeSettings() 
	{
		try
		{
	    	let obj = this.$data;
	    	//this.userUpdated();
	    	store( obj);
		} catch(e) { showError( this.$toast, "storeSettings()", e);  }
	},
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	}
  },
  beforeDestroy() {
    this.storeSettings();
  },
  created() {
	if ( sessionStorage.lastMediaId )
	{
		this.stationId = sessionStorage.lastMediaId;
	}
	
	let restored = restore();
	if ( restored && restore.data )
	{
		this.data = restored.data;
		this.dataStored = restored.dataStored;
	}

	this.date = new Date().toISOString().split('T')[0]
	this.loadStation();

	initAppMode();

  },
  watch: {
   stationId: function() {

   },
   interfaceId:    function() {
   	    this.activeInterface = this.interfacesFull.find( p=>p.id==this.interfaceId)
   		
   	},
  
   advertiser: function() {
	if ( this.advertiser)
	{
		let spots = this.data.existing.filter( p=>p.clientId == this.advertiser)
		let housenumbers = spots.map(p=>p.housenumber);
		housenumbers =  [...new Set(housenumbers)];
		let copyArr = [];
		copyArr.push({ id: 0, label:" - no filter -", visible: true});
		for ( let d in housenumbers)
		{
			let copyNo = parseInt( housenumbers[d]);
			copyArr.push({ id: copyNo, label:this.getCopy( spots, copyNo), visible: true});				
		}
		copyArr.sort( (a,b) => a.label.localeCompare(b.label));
		this.allCopies = copyArr;
	  }
	  else
	  {
		this.allCopies = [];
		this.allCopies.push({ id: 0, label:" - no filter -", visible: true});
	  }
	  this.copy = 0;
   }
  },
  updated() {
  		//console.log("update...");

 	}
}
</script>

<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 9pt;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 9pt;
}

.right {
  text-align: right !important;
  padding-right: 3pt; 
}
.todrag {
 display: block;
 width: 100%; 
 height: 16pt;
 border-left: 1pt solid #ccc; 
 border-bottom: 1pt solid #ccc;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow-x: hidden;
  width: 100%;
  display: block;
}
.progTitle {
  white-space: normal;
  width: 100%;
  display: flex;
  border: 1pt solid grey;
}
.smallText {
  font-size: 9pt;
  height: 12pt;
  
}
.BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.floatLeft {
    float: left;
}
.spotDetail { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt;  
  width: 100% !important; 
  height: 16pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;

  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.uploadArea {
 float: left; 
 width: 72%;  
 height: calc(100vH - 120px);
 border: 1pt dotted #ccc;
}
.reqLineBG {
	border: 2pt solid red !important;
}
.reqLineBG2 {
    cursor: pointer;
    color: #fff;
	background-color: #888 !important;
	//box-shadow: 0pt 2pt 0pt 0 #888;
}
.header {
  font-size: 14pt;
  font-weight: bold;
}
.spotTdS {
  font-size: 9pt;
  max-width: 6ex;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.xbutton
{
	font-size: 10pt;
	width: 140pt;
	padding: 0;
	height: 22pt;
	text-align: left;
}
.xlbutton
{
	width: 140pt;
	font-size: 10pt;
	padding: 0;
	height: 22pt;
	text-align: left;
	white-space: nowrap; /* Don't forget this one */
	  text-overflow: ellipsis; 
	  overflow: hidden;
}
.upbutton
{
	font-size: 10pt;
	width: 140pt;
	padding: 0;
	height: 22pt;
}
.vmbutton
{
	font-size: 10pt;
	width: 90pt;
	padding: 0;
	height: 22pt;
}
.uplongbutton
{
	font-size: 10pt;
	width: 120pt;
	padding: 0;
	height: 22pt;
}
.spotTdM2 {
  font-size: 9pt;
  max-width: 20ex;
  width: 20ex;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.spotTdM {
  font-size: 9pt;
  max-width: 14ex;
  width: 14ex;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.breakValL {
  display: inline-block;
  width: 30ex;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.nobold {
	font-weight: normal !important;
}
.breakVal {
  display: inline-block;
  width: 10ex;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.breakVal2 {
  display: inline-block;
  width: 14ex;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.spotTdL {
  font-size: 9pt;
  max-width: 50ex;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.RDHeader {
    display: inline;
	width: 99% !important;
	font-size: 12px;
	height: 100% !important;
	font-weight: bold;
	color: #000;
	background-color: #efe;
}
.breakLine:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
.spotLine:hover { background-color: #ccc !important; color: #000; }
</style> 